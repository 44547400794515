export default {
  START_TIME: "START_TIME",
  PAUSE_TIME: "PAUSE_TIME",
  END_TIME: "END_TIME",
  RESET_TIME: "RESET_TIME",
  ADD_NEW_TIMER: "ADD_NEW_TIMER",
  ADD_REST_INTERVALS: "ADD_REST_INTERVALS",
  REMOVE_REST_INTERVALS: "REMOVE_REST_INTERVALS",
  TOGGLE_REST_INTERVALS: "TOGGLE_REST_INTERVALS", // this to switch the boolean controlling the intervals
  DELETE_TIMER: "DELETE_TIMER",
  // DELETE_TIMERS: "DELETE_TIMERS",
  STAR_TIMER: "STAR_TIMER",
  SET_TIME: "SET_TIME",
  STOP_TIMER: "STOP_TIMER",
  ADD_TIME: "ADD_TIME",
  REDUCE_TIME: "REDUCE_TIME",
  TAKE_SNAPSHOT: "TAKE_SNAPSHOT",
  SET_TIMER_TITLE: "SET_TIMER_TITLE",
  SHOW_CREDITS: "SHOW_CREDITS",
  SHOW_HELP: "SHOW_HELP",
  SHOW_SHARE_MODAL: "SHOW_SHARE_MODAL",
  SET_WORKOUT: "SET_WORKOUT",
  SET_WORKOUT_SHORT_URL: "SET_WORKOUT_SHORT_URL",
};
