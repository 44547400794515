export const TIME_INCREMENT = 5;
export const DEFAULT_NEW_TIME_OBJECT = { time: 30, isActive: false };
export const DEFAULT_REST_INTERVAL = {
  time: 15,
  isRestInvertal: true,
  title: "Rest ",
};
export const INITAL_TIMERS_SET = [
  {
    time: 60,
    isActive: false,
  },
];
